import React from "react";
import styled from "styled-components";
import { theme } from "../components/Theme";
import { useClient } from "../context/ClientContext";
import { useModal } from "../context/ModalContext";
import { deleteClient } from "../firebase/clients";

const StyledButton = styled.button`
  background: ${theme.colors.blue};
  color: ${theme.colors.white};
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  &:hover {
    background: ${theme.colors.lightBlue};
    cursor: pointer;
  }
`;
const StyledHeader = styled.div`
  color: ${theme.colors.blue};
  h2,
  p {
    margin: 0;
    padding: 0px 0px 20px 0;
  }
`;

const DeleteClientModal = ({ clientId }: { clientId: string }) => {
  const { getAllClientData } = useClient();
  const { setActiveModal } = useModal();
  return (
    <>
      <StyledHeader>
        <h2>Delete Assisstance</h2>
        <p>Are you sure you want to delete this assistance?</p>
        <p>WARNING: This will remove all assistance tied to this client!!</p>
      </StyledHeader>

      <StyledButton
        onClick={async () => {
          await deleteClient({ clientId });
          if (getAllClientData) {
            await getAllClientData();
          }
          setActiveModal("");
        }}
      >
        Yes
      </StyledButton>
    </>
  );
};

export default DeleteClientModal;
